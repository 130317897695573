<template>
  <div>
    <StoreSettingsHeader
      :titleBarActions="titleBarActions"
      :hide-env-selector="editing"
    />
    <div class="col-12 platform-container">
      <template v-for="platform in sortedPlatforms">
        <StoreSettingsPlatformRow
          :key="platform.platformTypeID"
          :platform="platform"
          :editing="editing"
          @input="handleToggle"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import StoreSettingsHeader from "../components/store_settings_header.vue";
import StoreSettingsPlatformRow from "components/store_settings_platform_row.vue";
import clone from "clone";
import _ from "lodash";
import { platformMap } from "helpers/products";
import StoreSettingsMixin from "../mixins/store_settings.js";

export default {
    name: "StoreSettingsPlatforms",
    components: {
        StoreSettingsHeader,
        StoreSettingsPlatformRow
    },
    mixins: [StoreSettingsMixin],
    data() {
        return {
            platforms: [],
            editing: false
        };
    },
    computed: {
        titleBarActions() {
            const edit = {
                type: "edit",
                display: "Edit",
                run: () => {
                    this.editing = true;
                }
            };

            const cancel = {
                type: "cancel",
                display: "Cancel",
                run: () => {
                    this.editing = false;
                    this.platforms = clone(this.storePlatforms);
                }
            };

            const save = {
                type: "proceed",
                display: "Save",
                run: () => {
                    this.editing = false;
                    this.savePlatforms({
                        platforms: this.platforms,
                        env: this.env
                    });
                }
            };

            const allOn = {
                type: "green",
                noIcon: true,
                display: "Turn All Platforms Online",
                run: () => {
                    this.platforms = this.platforms.map(el => {
                        return {
                            ...el,
                            storeOnline: true
                        };
                    });
                }
            };

            const allOff = {
                type: "red",
                noIcon: true,
                display: "Turn All Platforms Offline",
                run: () => {
                    this.platforms = this.platforms.map(el => {
                        return {
                            ...el,
                            storeOnline: el.platformTypeID == 0 ? true : false
                        };
                    });
                }
            };

            if (this.editing) {
                // return [allOn, allOff, save, cancel]
                return [allOn, save, cancel];
            } else {
                return [edit];
            }
        },
        sortedPlatforms() {
            if (this.platforms === undefined) return [];
            const filteredPlatforms = this.platforms
                .filter(platform => {
                    return platformMap[platform.platformTypeID];
                })
                .map(platform => {
                    return {
                        ...platform,
                        display: platformMap[platform.platformTypeID].display,
                        order: platformMap[platform.platformTypeID].order
                    };
                });
            return _.sortBy(filteredPlatforms, el => el.order);
        },
        storePlatforms() {
            if (this.env == "stage" && this.platformsStage != undefined) {
                return this.platformsStage
            } else if (this.env == "prod" && this.platformsProd != undefined) {
                return this.platformsProd
            }
            return []
        },
        ...mapGetters(["store"]),
        ...mapGetters("SettingsStore", ["platformsStage", "platformsProd"])
    },
    watch: {
        store() {
            this.resolvePageTitle();
        },
        storePlatforms() {
            this.platforms = clone(this.storePlatforms);
        },
        env() {
            this.loadData();
        },
    },
    created() {
        this.resolvePageTitle();

        this.loadData();
    },
    methods: {
        loadData() {
            this.getPlatforms({
                waypointID: this.$route.params.store,
                env: this.env
            })
            this.platforms = clone(this.storePlatforms);
        },
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Platforms`;
            }
        },
        handleToggle(obj) {
            const index = this.platforms.findIndex(
                el => el.platformTypeID == obj.platformTypeID
            );
            var cloned = clone(this.platforms[index]);
            cloned.storeOnline = obj.value;
            this.platforms.splice(index, 1, cloned);
        },
        ...mapActions("SettingsStore", ["savePlatforms", "getPlatforms"])
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/mixins";

.platform-container {
  padding-left: 0px;
}
</style>
