import clone from 'clone'
import { upsert } from 'helpers'

export const OMNI_posSizeIDIfExists = (product) => {
    if (product === null || product === undefined) return null
    if (product.pos_productSizes.length < 1) return null
    const productSize = product.pos_productSizes[0]
    return productSize.pos_sizeID || null
}

export const OMNI_SubSizeTransform = (sub, posProducts, mainPosProductID) => {
    if (sub.pos_sizeID && sub.pos_productID) return sub
    // if (sub.inventoryItemSubName == "MAIN") {
    //     return {
    //         ...sub,
    //         pos_sizeID: sub.pos_productID,
    //         pos_productID: mainPosProductID
    //     }
    // }
    let posProductBySizeID = posProducts.find(el => el.pos_productID == sub.pos_sizeID)
    if (posProductBySizeID == undefined || (sub.inventoryItemSubName == "MAIN" && sub.pos_sizeID == 1 && posProductBySizeID != undefined)) return sub
    return {
        ...sub,
        pos_sizeID: OMNI_posSizeIDIfExists(posProductBySizeID),
        pos_productID: posProductBySizeID.pos_productID
    }
}
export const resolveProductModSets = (passedProduct, originalOptions) => {
    const hasModSets = passedProduct.modSets.length

    if (hasModSets) {
        const product = clone(passedProduct)

        const modSets = product.modSets
            .map(modSet => {
                const children = modSet.children
                    .map(group => {
                        return originalOptions.find(modGroup => modGroup.optionID === group.optionID)
                    })
                    .filter(group => group !== undefined)


                return Object.assign(modSet, {
                    inventoryItemIDs: upsert(modSet.inventoryItemIDs, product.inventoryItemID),
                    children,
                })
            })

        return modSets
    }

    return false
}

export const platformMap = {
    1: {
        display: "Mobile",
        shortDisplay: "Mob.",
        order: 3,
        id: 1,
    },
    2: {
        display: "Kiosk",
        shortDisplay: "Kiosk",
        order: 1,
        id: 2,
    },
    3: {
        display: "OAT",
        shortDisplay: "OAT",
        order: 2,
        id: 3,
    },
    4: {
        display: "Responsive Web",
        shortDisplay: "RW",
        order: 5,
        id: 4,
    },
    5: {
        display: "Delivery",
        shortDisplay: "Deliv",
        order: 4,
        id: 5
    },
    7: {
        display: "Virtual Kiosk",
        shortDisplay: "VK",
        order: 6,
        id: 7,
    },
    8: {
        display: "API",
        shortDisplay: "API",
        order: 7,
        id: 8,
    },
    9: {
        display: "Marketplace",
        shortDisplay: "MKT",
        order: 8,
        id: 9,
    }
}

export const platformStringForProduct = (product) => {

    if (product.platformInventory === undefined || product.platformInventory.length == 0) return 'N/A'

    const platforms = product.platformInventory.filter(platform => {
        return platformMap[platform.platformTypeID] && platform.bPlatformEnabled
    })

    if (platforms.length == 8) //max platforms
        return 'ALL'

    const shortDisplays = platforms.map(platform => {
        return platformMap[platform.platformTypeID].shortDisplay
    })

    return shortDisplays.join('/')
}

export const comboBannerStringForProduct = (product, comboEngine) => {
    var ret = ""
    for (let index = 0; index < comboEngine.comboChoiceGroups.length; index++) {
        const element = comboEngine.comboChoiceGroups[index];

        let found = element.comboChoiceGroupDetails.findIndex(el => el.inventoryItemID == product.inventoryItemID)

        if (found != -1 && ret.length == 0) {
            ret += `This item is included in the Combo Choice Group: ${element.comboChoiceGroupID}`
        } else if (found != -1 && ret.length > 0) {
            ret += `, ${element.comboChoiceGroupID}`
        }
    }

    if (ret.length > 0) return ret

    for (let index = 0; index < comboEngine.comboGroups.length; index++) {
        const element = comboEngine.comboGroups[index];

        let found = element.comboGroupDetails.findIndex(el => el.inventoryItemID == product.inventoryItemID)
        if (found != -1 && ret.length == 0) {
            ret += `This item is part of a Combo(s): ${element.comboID}`
        } else if (found != -1 && ret.length > 0) {
            ret += `, ${element.comboID}`
        }
    }

    return ret

}