var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("StoreSettingsHeader", {
        attrs: {
          titleBarActions: _vm.titleBarActions,
          "hide-env-selector": _vm.editing,
        },
      }),
      _c(
        "div",
        { staticClass: "col-12 platform-container" },
        [
          _vm._l(_vm.sortedPlatforms, function (platform) {
            return [
              _c("StoreSettingsPlatformRow", {
                key: platform.platformTypeID,
                attrs: { platform: platform, editing: _vm.editing },
                on: { input: _vm.handleToggle },
              }),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }