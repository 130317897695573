<template>
    <LineItem class="height-xs--7">
        <div 
            class="platform_row__line row no-gutters height-xs--inherit align-items-center"
        >
            <div class="platform_row__head col-2">
                <div 
                    class="platform_row__title">
                        {{ this.platform.display}}
                    </div>
            </div>
            <div
                v-if="editing" 
                class="platform_row__head col-2">
                <Toggle
                    :value="platform.storeOnline"
                    :label="labelString"
                    :labelColor="labelColor" 
                    slider="true"
                    labelWidth="75px"
                    @input="handleToggle"
                />
            </div>
            <div v-else>
                <span class="label" :style="{color: labelColor}"> {{ labelString }} </span>
            </div>
        </div>
    </LineItem>
</template>

<script>
import LineItem from 'components/line_item.vue'
import { mapGetters } from 'vuex'
import Toggle from 'components/cleverly/Toggle.vue'

export default {
    name: 'TableNumberRow',
    components: {
        LineItem,
        Toggle,
    },
    props: {
        platform: {
            type: Object,
            required: false,
            default: () => {},
        },
        editing: {
            type: Boolean,
            required: true,
        }
    },
    data() {
        return {
        
        }
    },
    computed: {
        labelString() {
            return this.platform.storeOnline ? 'Available' : 'Unavailable'
        },
        labelColor() {
            return this.platform.storeOnline ? '#93BD20' : '#d1d1d1'
        }
    },
    methods: {
        handleToggle(value) {
            this.$emit('input', {
                value: value,
                platformTypeID: this.platform.platformTypeID
            })
        }
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/variables';
@import '~scss/mixins';

.platform_row {
    padding-top: 10px;



    &__line {
        background-color: #fff;
        cursor: pointer;
        padding-left: 30px;
        .label {
            font-family: 'Roboto', sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 14px !important;
        }
    }

    &__action {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-top: 4px;
        margin-left: spacing(xs);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
    }

    &__sort {
        cursor: grab;

        &:active {
            cursor: grabbing;
        }
    }

    &__delete {
        background-image: url('~img/delete.svg');
        background-size: 15px;
    }

    &__head {
        display: flex;
        font-size: fz(sm);
        .toggle {
            .label {
                width: 100px !important;
            }
        }
    }

    &__icon {
        @include background-image('', 'partner_normal.svg', '../img/');
        background-size: contain;
        width: spacing(sm);
        height: spacing(sm);
        margin-top: 2px;

        &--active {
            @include background-image('', 'partner_open.svg', '../img/');
        }
    }

    &__title {
        margin-left: spacing(xs);
    }

    &__expanded {
        height: 346px !important;
        transition: max-height 100ms;
        background-color: #fff;
        overflow: hidden;

        &--inner {
            min-height: 10px;
        }

        &__info {
            padding-left: 30px !important;
        }
    }

    &__notes {
        padding-top: 30px;
        &__key {
            padding-left: 64px !important;
            color: #555555;	
            font-family: Roboto;	
            font-size: 14px;	
            font-weight: bold;	
            line-height: 16px;
        }

        &__value {
            padding-left: 80px !important;
        }
    }

    &__edit_links {
        padding-left: 64px !important;
        padding-top: 10px;
        a {
            color: $brand_color;
        }

        span {
            color: #FF0000;
        }
    }
    

    &--active {
        .platform_row__title {
            @include below(xs) {
                max-width: 115px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }
}
</style>
